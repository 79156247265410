import React, { useState, useEffect } from 'react';
import './ProgressBar.scss'

function ProgressBar({progress=0, v1, paused, progressTouchCb}) {
    progress = progress ? progress : 0
    
    let e = document.getElementsByClassName('progressBarProgress')[0]

    return ( <>
    <div className='progressWrap'>
        <button id='playButton'></button>
        {/* <div className='progressShell' onClick={progressTouchCb}>
            <div className='progressBarProgress' style={{width:paused ? e ? window.getComputedStyle(e).getPropertyValue('width'):0 : progress+'%'}}>
            </div>
        </div> */}
    </div>
    </> );
}

export default ProgressBar;