



function FourOhFour(){ 


    return(<>{"404 Page not found"}</>)
}

export default FourOhFour;
