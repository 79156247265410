function fadeIn(theClass, display='flex', addToLedger=true, visibilityLedger=null){
    document.getElementsByClassName(theClass)[0].style.display=display
    setTimeout(() => {
        document.getElementsByClassName(theClass)[0].style.opacity=1
    }, 50);

    if(addToLedger){
        if(visibilityLedger) visibilityLedger.push(theClass)
    }
}

function fadeOut(theClass, addToLedger=true, visibilityLedger=null){
    document.getElementsByClassName(theClass)[0].style.opacity=0
    
    setTimeout(() => {
        document.getElementsByClassName(theClass)[0].style.display='none'
    }, 901);

    if(addToLedger){
        if(visibilityLedger) {
            if(visibilityLedger.indexOf(theClass) > -1){
                if(visibilityLedger.length == 1){
                    //this is the last item
                    visibilityLedger.pop()
                }
                else{
                    visibilityLedger.splice(visibilityLedger.indexOf(theClass), 1)
                }
            }
        }
    }
    
}

export {fadeOut, fadeIn}