

import './main.scss'
import './EnrollmentForm.scss'
import './DpnStudy.scss';
import './HomePageContent.scss'

import React, {useState, useRef, useEffect, useContext} from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist"
import SessionContext from './SessionContext';
import {getCookie,toggleShowPassword, generateToken, collectData, transmuteKey} from './Utilities';
import VerifiedEmailInput from './VerifiedEmailInput';
import {show_spinner, hide_spinner, send_error, send_success} from './SpinnerLoader'
import {toast} from './Toast'
import ReCAPTCHA from "react-google-recaptcha";

import hipaaBadge from './hipaa-badge.png'
import { SubmissionTypes } from './Constants';
import {fadeOut, fadeIn} from './EffectSequencer.js'


function EnrollmentForm() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")

    const [wOffer, setWOffer] = useState(false)

    const sessionData = useContext(SessionContext)

    const [showPasswordChecklist, setShowPasswordChecklist] = useState(false)

    const passwordMeetsChecklist = useRef(false)
    let navigate = useNavigate()

    const recaptchaElement = useRef()

    const [captchaToken, setCaptchaToken] = useState(null)

    function captchaChange(t){
        setCaptchaToken(t)
    }

    function send_server_submission(myData,button, spinner, inner, checkBox, jotData){
        fetch('https://www.help4neuropathy.com/register/', {
                method: 'POST', 
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', 
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken':getCookie('csrftoken'),
                },
                body: new URLSearchParams(myData) // body data type must match "Content-Type" header
                }).then(response => response.json())
                .then(data=>{
                    if('success' in data){
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-10967892538/PYDhCMvO5oIYELqE8-0o',
                            'value': 15.0,
                            'currency': 'USD',
                          });
                        send_success(checkBox, spinner, inner)
                        toast('success',"Enrollment successful",document.querySelector('.surveyWrapper'), 'top')
                        setTimeout(() => {
                            fadeOut('enrollmentFormWrapper')
                            setTimeout(() => {
                                fadeIn('thankYouMessage')
                                fadeIn('slideshowRestartBox')
                            }, 1000);
                        }, 2000);
                    }
                    else if('err' in data){
                        recaptchaElement?.current.reset()
                        send_error(button, spinner, inner)
                        // for(let [k,v] of Object.entries(data.err)){
                        //     toast('err',""+k+": "+v,document.querySelector('.surveyWrapper'), 'top')
                        // }
                        document.querySelector(".formErrors").innerHTML = ''
                        for (const [key, value] of Object.entries(data.err)) {
                            const error = document.createElement("li");
                            error.textContent = key+' : '+value
                            document.querySelector(".formErrors").appendChild(error);
                            }
                    }
                    else{
                        send_error(button, spinner, inner, checkBox)
                        toast('err',"Registration error, please check internet and try again, or contact support for help enrolling",document.querySelector('.surveyWrapper'), 'top')
                    }
                })
                .catch(error => {
                    send_error(button, spinner, inner, checkBox)
                    toast('err',"Network error, please check internet and try again, or contact support for help enrolling",document.querySelector('.surveyWrapper'), 'top')
                });
    }

    function submitForm(e){
        e.preventDefault()

        if(!captchaToken){
            var data={'captcha':'please complete captcha'}
            document.querySelector(".formErrors").innerHTML = ''
            for (const [key, value] of Object.entries(data)) {
                const error = document.createElement("li");
                error.textContent = value
                document.querySelector(".formErrors").appendChild(error);
                }
            return
        }

        var button = document.querySelector(".submitButton[data='enrollFormEnroll']")
        var spinner = document.querySelector(".spinner[data='enrollFormEnroll']")
        var inner = document.querySelector(".inner[data='enrollFormEnroll']")
        var checkBox = document.querySelector('.check-box[data="enrollFormEnroll"]')
                
        show_spinner(button, spinner, inner)

        // if(!passwordMeetsChecklist.current){
        //     console.log('invalid password')
        //     return
        // }
        var randomSubmissionID = generateToken()

        var jotData = collectData(e,['password','password_verify','email_validation_token','zip'])
        
        var myData  = collectData(e,[],['zip','q5_name[first]','q5_name[last]','q8_ageToday','q7_gender','q44_email','q36_sendMe[]','password','password_verify','email_validation_token'])
                                        //q48_submissionId q49_userId 
        transmuteKey(myData,'q36_sendMe[]','email_reminder','True')
        transmuteKey(myData,'password','password1')
        transmuteKey(myData,'password_verify','password2')
        transmuteKey(myData,'q44_email','email')

        transmuteKey(myData,'q5_name[first]','first_name')
        transmuteKey(myData,'q5_name[last]','last_name')
        transmuteKey(myData,'q8_ageToday','age')
        transmuteKey(myData,'q7_gender','gender')

        //must be the same ID to associate the data
        myData['submission_id'] = randomSubmissionID
        myData['submission_type'] = SubmissionTypes.ENROLLMENT

        myData['captchaToken'] = captchaToken
        
        jotData['q48_submissionId'] = randomSubmissionID


        fetch('https://hipaa.jotform.com/submit/222424241871046/', {
            method: 'POST', 
            withCredentials: true,
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(jotData) // body data type must match "Content-Type" header
            }).then(response => {
                if(response.status == 200){
                    send_server_submission(myData,button, spinner, inner, checkBox, jotData)
                }
                else{
                    send_error(button, spinner, inner, checkBox)
                    toast('err',"Network error, please check internet and try again, or contact support for help enrolling",document.querySelector('.surveyWrapper'), 'top')
                }
                
            })
            .catch(error => {
                console.log(error)
                send_error(button, spinner, inner, checkBox)
                toast('err',"Network error, please check internet and try again, or contact support for help enrolling",document.querySelector('.surveyWrapper'), 'top')
            });

        
    // fetch('https://hipaa.jotform.com/server/check-form-password', {
    //         method: 'POST', 
    //         withCredentials: true,
    //         headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',

    //         },
    //         body: new URLSearchParams(loginData) // body data type must match "Content-Type" header
    //     }).then(response => {
    //         console.log(response)
    //         response.json()
    //     })
    //     .then(data=>{
    //         console.log(data)
    //     })
    //     .catch(error => {
    //         console.log(error)
    //     });

    }

    function clearAndDisableSA1(){
        document.getElementById('SA_1').value=''
        document.getElementById('SA_1').setAttribute('disabled','disabled')
        document.getElementById('SA_1').classList.add('SAdisabled')
        document.getElementById('SA_blur_handle').classList.add('blur')
        document.getElementById('SA_1_asterisk').classList.add('hidden')
        
    }
    function enableSA1(){
        document.getElementById('SA_1').removeAttribute('disabled')
        document.getElementById('SA_1').classList.remove('SAdisabled')
        document.getElementById('SA_blur_handle').classList.remove('blur')
        document.getElementById('SA_1_asterisk').classList.remove('hidden')

    }

    function clearAndDisableSA2(){
        document.getElementById('SA_2').value=''
        document.getElementById('SA_2').setAttribute('disabled','disabled')
        document.getElementById('SA_2').classList.add('SAdisabled')
        document.getElementById('SA_blur_handle2').classList.add('blur')
        document.getElementById('SA_2_asterisk').classList.add('hidden')
        
    }
    function enableSA2(){
        document.getElementById('SA_2').removeAttribute('disabled')
        document.getElementById('SA_2').classList.remove('SAdisabled')
        document.getElementById('SA_blur_handle2').classList.remove('blur')
        document.getElementById('SA_2_asterisk').classList.remove('hidden')

    }



    function setPreDNo(){
        document.getElementById('radNo_7').checked = true
        
    }
    function setDNo(){
        document.getElementById('radNo_5').checked = true
    }

    return(<>
        <div className='enrollmentFormWrapper'>
            <div className='surveyWrapper'>
            

                <p className='surveyHeader'><b>Enrollment Questionnaire</b></p>


                {/* <button className='submitButton bgRed' onClick={() => navigate("/l/")}>I'm Already Enrolled, Login Now</button> TODO SIGNIN CHANGE REMOVED*/}

                <form className='enrollmentForm' onSubmit={e => submitForm(e)}>

                    <input type="hidden" name="formID" value="222424241871046" />




                    <div className='eName'>

                        <div className='eEName'>
                            <div><label style={{ wordBreak: 'keep-all' }} htmlFor='q5_name[first]'>First Name<span className='required'>*</span></label></div>
                            <input className='surveyInput' required type='text' id='f_name' name="q5_name[first]" />
                        </div>
                        <div className='eEName'>
                            <div><label style={{ wordBreak: 'keep-all' }} htmlFor='q5_name[last]'>Last Name<span className='required'>*</span></label></div>
                            <input className='surveyInput' required type='text' id='l_name' name="q5_name[last]" />
                        </div>
                    </div>
                    <div className='genderAndBadge'>
                        <table>
                            <tbody>
                                <tr className='eTopRow'>
                                    <td className='eGenderRow'>Gender
                                        <span className='required'>*</span>
                                    </td>
                                    <td>
                                        <div className='genderResponseArea'>
                                            <div className='genderCol'>
                                                <label className='TFLabel' htmlFor='radYes_1'>M</label>
                                                <input required type='radio' id='radYes_1' name="q7_gender" value='Male' />
                                            </div>
                                            <div className='genderCol'>
                                                <label className='TFLabel' htmlFor='radNo_1'>F</label>
                                                <input required type='radio' id='radNo_1' name="q7_gender" value='Female' />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='eTopRow'>
                                    <td>Age Today
                                        <span className='required'>*</span>
                                    </td>
                                    <td className='eAgeSelectorCell'>
                                        <select required className='eAgeSelector' name='q8_ageToday'>
                                            <option value="">--please select--</option>
                                            {(() => {
                                                var options = []
                                                for (var v = 18; v <= 120; v++) {
                                                    options.push(<option value={v}>{v}</option>)
                                                }
                                                return options
                                            })()}

                                        </select>
                                    </td>

                                </tr>
                                <tr className='eTopRow'>
                                    <td>Zip
                                        <span className='required'>*</span>
                                    </td>
                                    <td>
                                        <div className='TFresponseArea'>
                                            <input pattern='^[0-9]{5}(?:-[0-9]{4})?$' name='zip' required type='text' style={{outline:'none',border:'none'}}/>
                                        </div>
                                    </td>

                                </tr>

                            </tbody>

                        </table>
                        <div className="badge-wrapper">
                            <img className="hipaa-badge" src={hipaaBadge} alt="hipaa compliant badge" style={{ display: "block", width: "95px" }} />
                        </div>
                    </div>

                    <table className='qTable'>
                        <tbody>
                            <tr>
                                <td colSpan='2'>
                                    Questions
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    1. I have peripheral neuropathy (numbness) in my feet.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_3'>Yes</label>
                                            <input required type='radio' id='radYes_3' name="q14_1I" value='Yes' onClick={() => enableSA1()} />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_3'>No</label>
                                            <input required type='radio' id='radNo_3' name="q14_1I" value='No' onClick={() => clearAndDisableSA1()} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id='SA_blur_handle'>
                                <td className='qCol'>
                                    2. I have had neuropathy for
                                    <span id='SA_1_asterisk' className='required'>*</span>
                                </td>
                                <td>
                                    <div className='SAresponseArea'>
                                        <select id='SA_1' required name='q16_2I'>
                                            <option value="">--</option>
                                            {(() => {
                                                var options = []
                                                for (var v = 0; v <= 9; v++) {
                                                    options.push(<option value={v}>{v}</option>)
                                                }
                                                return options
                                            })()}
                                            <option value="10+">10+</option>
                                        </select>
                                        <span>&nbsp;years</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    3. I have nerve pain or tingling in my feet.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes654'>Yes</label>
                                            <input required type='radio' id='radYes654' name="q50_3I50" value='Yes' onClick={() => enableSA2()} />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_1564'>No</label>
                                            <input required type='radio' id='radNo_1564' name="q50_3I50" value='No' onClick={() => clearAndDisableSA2()} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr id='SA_blur_handle2'>
                                <td className='qCol'>
                                    4. I have had nerve pain or tingling for<span id='SA_2_asterisk' className='required'>*</span>
                                </td>
                                <td>
                                    <div className='SAresponseArea'>
                                            <select id='SA_2' required name='q51_4I51'>
                                                <option value="">--</option>
                                                {(() => {
                                                    var options = []
                                                    for (var v = 0; v <= 9; v++) {
                                                        options.push(<option value={v}>{v}</option>)
                                                    }
                                                    return options
                                                })()}
                                                <option value="10+">10+</option>
                                            </select>
                                            <span>&nbsp;years</span>
                                        </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    5. I have been diagnosed with diabetes.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_5'>Yes</label>
                                            <input required type='radio' id='radYes_5' name="q17_5I17" value='Yes' onClick={() => setPreDNo()} />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_5'>No</label>
                                            <input required type='radio' id='radNo_5' name="q17_5I17" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    6. I have a prescription for insulin.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_6'>Yes</label>
                                            <input required type='radio' id='radYes_6' name="q18_6I18" value='Yes' />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_6'>No</label>
                                            <input required type='radio' id='radNo_6' name="q18_6I18" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    7. I have been diagnosed with prediabetes.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_7'>Yes</label>
                                            <input required type='radio' id='radYes_7' name="q19_7I19" value='Yes' onClick={() => setDNo()} />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_7'>No</label>
                                            <input required type='radio' id='radNo_7' name="q19_7I19" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    8. I have a prescription for Metformin.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_8'>Yes</label>
                                            <input required type='radio' id='radYes_8' name="q20_8I20" value='Yes' />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_8'>No</label>
                                            <input required type='radio' id='radNo_8' name="q20_8I20" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    9. I have a prescription for Gabapentin.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_9'>Yes</label>
                                            <input required type='radio' id='radYes_9' name="q21_9I" value='Yes' />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_9'>No</label>
                                            <input required type='radio' id='radNo_9' name="q21_9I" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='qCol'>
                                    10. I have wound healing problems.<span className='required'>*</span>
                                </td>
                                <td>
                                    <div className='TFresponseArea'>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radYes_10'>Yes</label>
                                            <input required type='radio' id='radYes_10' name="q22_10I" value='Yes' />
                                        </div>
                                        <div className='TFCol'>
                                            <label className='TFLabel' htmlFor='radNo_10'>No</label>
                                            <input required type='radio' id='radNo_10' name="q22_10I" value='No' />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='contactDetails'>
                        <div className='eEmailRow'>
                            <div className=''>
                                <div className='eEName'>
                                    <div><label style={{ wordBreak: 'keep-all' }} htmlFor='email'>Email Address To Enroll<span className='required'>*</span></label></div>
                                    <input style={{margin:'0 5px 5px 0'}} type='email' id='email'  name="q44_email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className="formValidatedInput" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
                                </div>
                            </div>
                        </div>
                        <div className='eEmailSub'>
                            <label htmlFor='email_subscribe'>Send me Questionnaire reminder emails</label>
                            <input type='checkbox' id='email_subscribe' name="q36_sendMe[]" value='True' />
                        </div>

                        <div >
                            <div className='passWrap'>
                                <div className='eEName'>
                                    <div><label style={{ wordBreak: 'keep-all' }} htmlFor='password'>Create Password<span className='required'>*</span></label></div>
                                    <input className='formValidatedInput' value={password} required type='password' pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" id='password' name="password" onChange={e =>
                                        {
                                            if(e.target.value != passwordAgain){
                                                document.querySelector('#password_verify').setCustomValidity('Passwords must match')
                                            }
                                            else{
                                                document.querySelector('#password_verify').setCustomValidity('')
                                            }
                                            if(e.target.value.length){
                                                setShowPasswordChecklist(true)
                                            }
                                            else{
                                                setShowPasswordChecklist(false)
                                            }
                                            setPassword(e.target.value)} } />
                                    <i className="bi bi-eye-slash" onClick={(e) => { toggleShowPassword(e, 'password') }}></i>
                                </div>
                                <div className='eEName'>
                                    <div><label style={{ wordBreak: 'keep-all' }} htmlFor='password_verify'>Verify Password<span className='required'>*</span></label></div>
                                    <input className='formValidatedInput' value={passwordAgain} required type='password' pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$" id='password_verify' name="password_verify" 
                                        onChange={e => {
                                            if(e.target.value != password){
                                                e.target.setCustomValidity('Passwords must match')
                                            }
                                            else{
                                                e.target.setCustomValidity('')
                                            }
                                            setPasswordAgain(e.target.value)}
                                            } />
                                    <i className="bi bi-eye-slash" onClick={(e) => { toggleShowPassword(e, 'password_verify') }}></i>
                                </div>
                                {showPasswordChecklist && 

                                        <PasswordChecklist
                                            rules={["minLength", "letter", "number", "specialChar", "match"]}
                                            minLength={8}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            onChange={(isValid) => {
                                                
                                                passwordMeetsChecklist.current = isValid
                                            }}
                                        />
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{marginBottom:'15px'}}>
                        <ReCAPTCHA 
                            sitekey="6LfWpx8jAAAAAGIEdiMJEUwvJT-H4pLIZxBtRrX6"
                            onChange={captchaChange}
                            ref={recaptchaElement}
                        />
                        <div className='formErrors'></div>
                    </div>

                    <div className='submitWrapper'>    
                    {wOffer ? 
                        (<>
                            
                                <button data='enrollFormEnroll' className='submitButton wOfferButton' type="submit" >
                                    Proceed to Wound Healing Offer
                                    <div data='enrollFormEnroll' className='check-box'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                            <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>
                                    </div>
                                </button>
                                <div data='enrollFormEnroll' className='spinner'>
                                </div>
                                <div data='enrollFormEnroll' className='inner wOfferInner'></div>
                            
                        </>)
                    :
                        (<>
                                <button data='enrollFormEnroll' className='submitButton' type="submit" >Enroll
                                    <div data='enrollFormEnroll' className='check-box'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                            <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                        </svg>
                                    </div>
                                </button>
                                <div data='enrollFormEnroll' className='spinner'>
                                </div>
                                <div data='enrollFormEnroll' className='inner'></div>
                        </>)
                    }
                    </div>

                    <div className='smallPrint'><span className='required'>*</span> = required response</div>

                    <input type="hidden" name="website" value="" />             
                    <input type="hidden" className="simple_spc" id="simple_spc" name="simple_spc" value="222424241871046-222424241871046" />
                    <input type="hidden" name="event_id" value="1662945356263_222424241871046_UVBuxqu" />       
                    <input id="file_server" type="hidden" name="file_server" value="hipaa-app1" />
                    <input id="target_env" type="hidden" name="target_env" value="hipaa" />
                </form>

                <div className='success_message'>
                    Enrolled successfully
                </div>


            </div>

            </div>
            <div className='slideShowHiddenSlide centeredBigText thankYouMessage'><p><b>Thank you for ENROLLING.  We will be back in touch in January when your sample case is ready to ship.</b></p>
                
            </div>
            <div className='slideShowHiddenSlide slideshowRestartBox' style={{display:'flex', justifyContent:'center'}} >
                <button className=' submitButton slideshowRestart'  onClick={() => {fadeOut('thankYouMessage'); 
                    fadeOut('slideshowRestartBox');
                    setTimeout(() => {
                    navigate('/')
                 }, 1000); }}>&#8619; Restart</button>
            </div>
    </>)
}

export default EnrollmentForm;