
import './Splash.scss';
import ProgressBar from './ProgressBar';

import {useState, useRef, useEffect, useLayoutEffect} from 'react'
import { useLocation,Link, useNavigate } from 'react-router-dom';
import {getCookie, generateToken, collectData, transmuteKey} from './Utilities';
import {show_spinner, send_error, send_success} from './SpinnerLoader'
import {toast} from './Toast'
import bot1 from './web_500ml_render_1_crop.png'
import bot2 from './web_500ml_render_2_crop.png'
import ingredients from './ingredients_dpn.png'
import {fadeOut, fadeIn} from './EffectSequencer.js'
import gncLogo from './gnc-live-well-2.png'

function Splash() {

    let navigate = useNavigate()

    function fadeOutLocal(theClass, addToLedger=true){
        fadeOut(theClass,addToLedger,visibilityLedger.current)

    }
    function fadeInLocal(theClass, display='flex', addToLedger=true){
        fadeIn(theClass,display,addToLedger,visibilityLedger.current)
    }


    function fadeOutLeaveIn(theClass){
        document.getElementsByClassName(theClass)[0].style.opacity=0
        
    }
    

    const [progress, _setProgress] = useState(null)
    const progressRef = useRef(null)
    function setProgress(d) {
        progressRef.current = d
        _setProgress(d)
    }
    const steps = useRef(14)

    const timerArray = useRef([])
    const timerIndex = useRef(0)
    const [paused,setPaused] = useState(false)

    const isDrugMart = false



    function setProgressSpeed(i){
        // document.getElementsByClassName('progressBarProgress')[0].style.transition ='width '+relativeTimeTable.current[i]/1000+'s linear'
    }

    const functionArray = useRef([])


    function addStep(f, myIndex){

        function c(t){
            return setTimeout(()=>{
                f()
                timerIndex.current = myIndex
                //console.log(timerIndex.current)
                setProgressSpeed(timerIndex.current)
                setProgress(timerIndex.current)
            },t)
        }

        functionArray.current.push(c)
    }

   
    const relativeTimeTable = useRef([]);
    const timerTable = useRef([]);
    const cumTimeTable = useRef([])

    const sum_cumTable = useRef(0)
    const sum_timerTable = useRef(0)
    const totalTime  = useRef(0) 

    const PAUSE_STEPS = useRef([])
    const visibilityLedger = useRef([])
    const slideDelineater = useRef([])


    function resetSlides(){
        var startingLength = visibilityLedger.current.length
        //console.log(visibilityLedger.current)
        for(var i=0;i<startingLength;i++){
            fadeOutLocal(visibilityLedger.current[i])
        }
        var continueButtons = document.querySelectorAll('.submitButton')
        for(var i=0;i<continueButtons.length;i++){
            continueButtons[i].removeAttribute('disabled')
        }
    }

    function resetInitialConditions(){
        //document.body.style.backgroundColor = '#124B99';
        fadeInLocal('splashWrapper')

    }

    function startFrom(slideNum){

        timerIndex.current = slideNum
        var nextPauseOrEnd = (()=>{
            for(var i=0;i<PAUSE_STEPS.current.length;i++){
                if(PAUSE_STEPS.current[i] > timerIndex.current){
                    return PAUSE_STEPS.current[i]
                }
            }
            return functionArray.current.length
        })()


        if(!(PAUSE_STEPS.current.includes(timerIndex.current ))){// if not in a pause step, resume
            if(timerIndex.current == 0){
                for(let i=timerIndex.current;i<PAUSE_STEPS.current[0];i++){
                    timerArray.current[i] = functionArray.current[i](timerTable.current[i])
                }
            }
            else{
                for(let i=timerIndex.current;i<nextPauseOrEnd;i++){
                    timerArray.current[i] = functionArray.current[i](timerTable.current[i]-timerTable.current[timerIndex.current-1])
                }
            }
        }
    }

    function restartShow(){
        
        for(let i=timerIndex.current;i<functionArray.current.length;i++){
            clearTimeout(timerArray.current[i])
        }
        resetSlides() //fade out and give time to fade out before restarting
        setTimeout(() => {
            resetInitialConditions()
            startFrom(0)
        }, 900);
    }

    const speed = 1;

    if(sum_cumTable.current == 0 && sum_timerTable.current== 0){
        var myIndex = 1

        slideDelineater.current.push(0)

        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('subheader')
        },myIndex++)

        
        relativeTimeTable.current.push(2000)
        addStep(()=>{
            fadeOutLocal('splashWrapper')
            fadeOutLocal('subheader')
            fadeOutLeaveIn('vsCopyRight')
        },myIndex++)

        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('welcomeSplash')
        },myIndex++)
        
        relativeTimeTable.current.push(2000*speed)
        addStep(()=>{
            fadeOutLocal('welcomeSplash')
            document.body.style.backgroundColor = '#FFFFFF'
        },myIndex++)
        
        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('introCopy')
        },myIndex++)

        relativeTimeTable.current.push(2000*speed)
        addStep(()=>{
            fadeInLocal('continueButton')
        },myIndex++)

        PAUSE_STEPS.current.push(myIndex-1)
        relativeTimeTable.current.push(50)
        addStep(()=>{
            fadeOutLocal('introCopy')
            fadeOutLocal('continueButton')
        },myIndex++)
        

        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('bottleImagesAndCopy')
        },myIndex++)
        PAUSE_STEPS.current.push(myIndex-1)

        relativeTimeTable.current.push(50)
        addStep(()=>{
            fadeOutLocal('bottleImagesAndCopy')
        },myIndex++) 

        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('ingredientsSlide')
        },myIndex++)
        PAUSE_STEPS.current.push(myIndex-1)

        relativeTimeTable.current.push(50)
        addStep(()=>{
            fadeOutLocal('ingredientsSlide')
        },myIndex++)

        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('for100','block')
        },myIndex++)

        relativeTimeTable.current.push(2000*speed)
        addStep(()=>{
            fadeInLocal('continueButton2')
        },myIndex++)
        PAUSE_STEPS.current.push(myIndex-1)

        relativeTimeTable.current.push(50)
        addStep(()=>{
            fadeOutLocal('for100')
            fadeOutLocal('continueButton2')
        },myIndex++)

        slideDelineater.current.push(myIndex-1)
        relativeTimeTable.current.push(1000*speed)
        addStep(()=>{
            fadeInLocal('mailingListSignupFormSlide')
        },myIndex++)

        PAUSE_STEPS.current.push(myIndex-1) //the number of steps prior to this one
        relativeTimeTable.current.push(50)
        addStep(()=>{
            fadeOutLocal('mailingListSignupFormSlide')
        },myIndex++)

        for(var i=0;i<relativeTimeTable.current.length;i++){
            sum_cumTable.current += relativeTimeTable.current[i]
            sum_timerTable.current += relativeTimeTable.current[i]
            cumTimeTable.current.push(sum_cumTable.current)
    
            if(relativeTimeTable.current[i] == 50){
                sum_timerTable.current = 50
                timerTable.current.push(sum_timerTable.current)
                sum_timerTable.current = 0;
            }
            else{
                timerTable.current.push(sum_timerTable.current)
            }
    
        }
        cumTimeTable.current.push(sum_cumTable.current)
        totalTime.current = sum_cumTable.current
    }



    useLayoutEffect(()=>{
        //document.body.style.backgroundColor = '#124B99';


    },[])


    function handleContinue(e){

        e.target.setAttribute('disabled','disabled')

        var nextPauseOrEnd = (()=>{
            for(var i=0;i<PAUSE_STEPS.current.length;i++){
                if(PAUSE_STEPS.current[i] > timerIndex.current){
                    return PAUSE_STEPS.current[i]
                }
            }
            return functionArray.current.length
        })()

        for(let i=timerIndex.current;i<nextPauseOrEnd;i++){
            timerArray.current[i] = functionArray.current[i](timerTable.current[i])
        }

    }

    function handleBack(){

        for(let i=timerIndex.current;i<functionArray.current.length;i++){
            clearTimeout(timerArray.current[i])
        }

       // console.log(timerIndex.current)

        resetSlides()

        //console.log(slideDelineater.current)

        timerIndex.current = timerIndex.current > 1 ? 
                            (()=>{
                                for(var i=slideDelineater.current.length-1;i>=0;i--){
                                    if(slideDelineater.current[i]<timerIndex.current-1){
                                        return slideDelineater.current[i]
                                    }
                                }
                            })()
                            :
                            0;

        //console.log(timerIndex.current)
        //console.log(PAUSE_STEPS.current)

        var nextPauseOrEnd = (()=>{
            for(var i=0;i<PAUSE_STEPS.current.length;i++){
                if(PAUSE_STEPS.current[i] > timerIndex.current){
                    return PAUSE_STEPS.current[i]
                }
            }
            return functionArray.current.length
        })()

        if(timerIndex.current == 0){
            resetInitialConditions()
            for(let i=timerIndex.current;i<PAUSE_STEPS.current[0];i++){
                timerArray.current[i] = functionArray.current[i](timerTable.current[i])
            }
        }
        else{
            for(let i=timerIndex.current;i<nextPauseOrEnd;i++){
                timerArray.current[i] = functionArray.current[i](timerTable.current[i]-timerTable.current[timerIndex.current-1])
            }
        }
        
    }

    function handlePause(e){

        if(e.target.classList.contains('submitButton')){
            return
        }
        
        document.getElementById('playButton').classList.add('paused')
        
        setPaused(true)

        for(let i=timerIndex.current;i<functionArray.current.length;i++){
            clearTimeout(timerArray.current[i])
        }
       
    }

    function handleResume(e){

        if(e.target.classList.contains('submitButton')){
            return
        }
        
        document.getElementById('playButton').classList.remove('paused')

        setPaused(false)
        //console.log(timerIndex.current)

        var nextPauseOrEnd = (()=>{
            for(var i=0;i<PAUSE_STEPS.current.length;i++){
                if(PAUSE_STEPS.current[i] > timerIndex.current){
                    return PAUSE_STEPS.current[i]
                }
            }
            return functionArray.current.length
        })()


        if(!(PAUSE_STEPS.current.includes(timerIndex.current ))){// if not in a pause step, resume
            if(timerIndex.current == 0){
                for(let i=timerIndex.current;i<PAUSE_STEPS.current[0];i++){
                    timerArray.current[i] = functionArray.current[i](timerTable.current[i])
                }
            }
            else{
                for(let i=timerIndex.current;i<nextPauseOrEnd;i++){
                    timerArray.current[i] = functionArray.current[i](timerTable.current[i]-timerTable.current[timerIndex.current-1])
                }
            }
        }
        

    }

    useEffect(()=>{
        
        // document.querySelector('.progressBarProgress').style.borderColor = '#124B99'

        document.body.style.transition ='background-color 1s ease'
        document.querySelector('.splashWrapper').style.transition ='opacity 1s ease'
        document.querySelector('.splashWrapper').style.opacity ='1'
        window.addEventListener('touchstart', handlePause);
        window.addEventListener('touchend', handleResume);
        window.addEventListener('mousedown', handlePause);
        window.addEventListener('mouseup', handleResume);
        //subheader HIDDEN needs show
        //splashWrapper VISIBLE needs hide
    
        setProgressSpeed(0)
        setTimeout(() => {
            setProgress(0)
        }, 50);

        for(let i=0;i<PAUSE_STEPS.current[0];i++){ //go to first pause
            timerArray.current[i] = functionArray.current[i](timerTable.current[i])
        }
        
        return () => {
            window.removeEventListener('touchstart', handlePause);
            window.removeEventListener('touchend', handleResume);
            window.removeEventListener('mousedown', handlePause);
            window.removeEventListener('mouseup', handleResume);
        }

        //style={{ whiteSpace: 'nowrap' }}<div style={{ marginTop: '20px' }}><b>Here's how you participate:</b></div>
    },[])

    function submitMailingSignup(e) {
        e.preventDefault()
        var button = document.querySelector(".submitButton[data='mailingSignupForm']")
        var spinner = document.querySelector(".spinner[data='mailingSignupForm']")
        var inner = document.querySelector(".inner[data='mailingSignupForm']")
        var checkBox = document.querySelector('.check-box[data="mailingSignupForm"]')

        show_spinner(button, spinner, inner)

        var myData = collectData(e)

        setTimeout(() => {
            send_success(checkBox, spinner, inner)
            toast('success',"Signed up successfully",document.querySelector('.tsthandle'), 'bottom')
        }, 200);
        
        setTimeout(() => {
            fadeOutLocal('mailingListSignupForm', false)
            fadeOutLocal('bigORtext', false)
            fadeOutLocal('enrollNowButton', false)
            setTimeout(() => {
                fadeInLocal('signupThankYou','flex', false)
            }, 1000);
        }, 2000);
        // fetch('https://www.help4neuropathy.com/mailing_signup/', {
        //         method: 'POST', 
        //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //         credentials: 'include', 
        //         headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'X-CSRFToken':getCookie('csrftoken'),
        //         },
        //         body: new URLSearchParams(myData) // body data type must match "Content-Type" header
        //         }).then((response) => {
        //             //console.log(response)
        //             return response.json()
        //         })
        //         .then(data=>{
        //             //console.log(data)
        //             if('err' in data){
        //                 send_error(button, spinner, inner)
        //                 toast('err',data.err,document.querySelector('.tsthandle'), 'top')
        //             }
        //             else if('success' in data){
        //                 window.gtag('event', 'conversion', {
        //                     'send_to': 'AW-10967892538/egSJCMXO5oIYELqE8-0o',
        //                     'value': 1.0,
        //                     'currency': 'USD',
        //                 });
                        
        //             } 
        //             else{
        //                 send_error(button, spinner, inner)
        //                 document.querySelector(".formErrors").innerHTML = ''
        //                 for (const [key, value] of Object.entries(data)) {
        //                     const error = document.createElement("li");
        //                     error.textContent = value
        //                     document.querySelector(".formErrors").appendChild(error);
        //                   }
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error)
        //             send_error(button, spinner, inner)
        //             toast('err',"error 0x68",document.querySelector('.tsthandle'), 'top')
        //         });


    }

    return(<>
        <div className='pageWrap'>
            <div className='vertColumn'>

                <div className='splashWrapper'>
                    <img src={gncLogo} style={{display:'block',height:'auto',width:'clamp(50px,80%,600px)'}} />
                </div>

                <div className='subheader' style={{color:'Black'}}>(This site is intended to convey a concept for a neuropathy website that enrolls GNC customers in consumer survey studies)</div>

                <div className='slideShowHiddenSlide welcomeSplash'>Welcome to help4neuropathy.com</div>
                
                <div className='slideShowHiddenSlide centeredBigText introCopy'><p>We want you to tell us what you think about <b>MaxSupport - Nerves</b> by participating in a four-week consumer survey. You can earn a <b>$100 Visa Gift Card</b> by completing four weekly online questionnaires.</p>
                </div>

                <div className="slideShowHiddenSlide bottleImagesAndCopy">

                    <div className='bottleImages'>
                        <div className='bottleImagesInner'>
                            <img src={bot1} style={{display:'block',height:'auto',width:'clamp(50px,100%,156px)'}} />
                        </div>
                        <div className='flex-column gap-20'>
                            <div className='advertCopy'>
                                <p><b>Max Support - NERVES</b> is a high-performance dietary supplement <b>DRINK</b> developed to support, maintain and protect peripheral nerve function and promote microvascular blood flow.
                                <br /><br /><b>Max Support - WOUNDS</b> is formulated to reduce edema and promote wound healing.</p>
                            </div>
                            <p className="sampleCartonText">Sample Cartons of <b>Max Support - Nerves</b> contain 30 - 16.9 fl (500 mL) bottles</p>
                        </div>

                        <div className='bottleImagesInner'>
                            <img src={bot2} style={{display:'block',height:'auto',width:'clamp(50px,100%,156px)'}}  />
                        </div>
                    </div>
                    <button className='submitButton ' onClick={(e) => { 
                        window.gtag('event', 'conversion', {'send_to': 'AW-10967892538/17KUCKOZ54IYELqE8-0o'});
                        handleContinue(e) }}>Continue to ingredients &#8594;</button>

                </div>

                <div className='slideShowHiddenSlide ingredientsSlide'>

                    <img src={ingredients} />

                    <button className='submitButton ingredientsContinue' onClick={(e) => { 
                        window.gtag('event', 'conversion', {'send_to': 'AW-10967892538/T5wTCKaZ54IYELqE8-0o'});
                        handleContinue(e) }}>Continue &#8594;</button>
                </div>

                <div className='for100'><p>The survey is open to anyone 18 and older who is a diabetic or prediabetic with peripheral neuropathy (numbness, pain, or tingling in their feet or fingers).</p></div>


                <div className='slideShowHiddenSlide continueButton'>
                    <button className='submitButton width-100' onClick={(e) => { 
                        window.gtag('event', 'conversion', {'send_to': 'AW-10967892538/6QkECKCZ54IYELqE8-0o'});
                        handleContinue(e) }}>Continue &#8594;</button>
                </div>

                <div className='slideShowHiddenSlide continueButton2'>
                    <button className='submitButton width-100' onClick={(e) => { 
                        window.gtag('event', 'conversion', {'send_to': 'AW-10967892538/fuGWCKmZ54IYELqE8-0o'});
                        handleContinue(e) }}>Continue &#8594;</button>
                </div>

                <div className='slideShowHiddenSlide mailingListSignupFormSlide' >

                    <div style={{minHeight:'400px',marginBottom:'20px'}}>

                        <form className='mailingListSignupForm' onSubmit={(e) => {submitMailingSignup(e)}}>
                            <p style={{fontSize:'30px', fontWeight:'700', textAlign:'left'}}>Sign up HERE for an email telling you when enrollment begins.</p>
                            
                            
                            <input  type='email' placeholder='Email' name='email' className="inputStyled" />
                            <input  type='text' placeholder='Zip Code' name='zip' className="inputStyled"/>

                            <div className='submitWrapper'>
                            <button data='mailingSignupForm' className='submitButton' type='submit' style={{width:'100%' }}>Sign-up for the enrollment email
                                        <div data='mailingSignupForm' className='check-box' >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                        <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                        </div>

                                    </button>
                                    <div data='mailingSignupForm' className='spinner'>
                                        
                                    </div>
                                    <div data='mailingSignupForm' className='inner'></div>
                                </div>

                            <div className='formErrors'></div>
                                
                        </form>

                        <div className='bigORtext centeredBigText smallerOr'> OR </div>

                        <div className="slideShowHiddenSlide slide signupThankYou"><p><b>Thank you for signing up for more information. We will be back in touch when enrollment begins.</b></p></div>
                    </div>

                    <div className='enrollNowButton'>
                        <button className='submitButton ' onClick={(e) => { handleContinue(e);setTimeout(() => {
                            navigate('/e')
                        }, 1000); }}>Enrollment is limited so ENROLL NOW</button>
                    </div>

                    <button className='submitButton slideshowRestart' onClick={() => { restartShow() }}>&#8619; Restart</button>
                    
                </div>

            </div>



        </div>
                <div className='vsCopyRight'>©2022 Vascular Scientific, LLC</div>
                <ProgressBar progress={(cumTimeTable.current[progressRef.current]/totalTime.current)*100} paused={paused} v1={progress} />
                <div className='tsthandle'></div>
        
    </>)
}

export default Splash;