import logo from './logo.svg';
import './App.css';
import React, { useEffect, useReducer, useState, useRef } from "react";
import { Routes ,Route, Navigate, useSearchParams } from 'react-router-dom';
import EnrollmentForm from './EnrollmentForm'
import FourOhFour from './FourOhFour'
import SessionContext from './SessionContext';
import Splash from './Splash'
import DpnStudy from './DpnStudy'
// import LoggedIn from './LoggedIn'
// import NavBar from './NavBar'
// import AddressForm from './AddressForm'
// import RateFeeling from './RateFeeling'
// import WoundInfo from './WoundInfo'
// import EnrollThanks from './EnrollThanks';
// import BuyNowSlides from './BuyNowSlides'
// import AddressChangeForm from './AddressChangeForm';
//const LoggedIn = lazy(() => import('./LoggedIn'));

function App() {

  const [sessionData, _setSessionData] = useState({"authenticated":false, "id": null, "qstate":null})
  const sessionDataRef = useRef((window.init.id && window.init.id != 'None') ? 
                                {"authenticated":true, "id": window.init.id, "qstate":window.init.qstate}
                                : 
                                {"authenticated":false, "id": null, "qstate":null})

  function setSessionData(d){
    sessionDataRef.current = d
    _setSessionData(d)
  }
  function getSession(){
    return sessionDataRef.current
  }

  useEffect(()=>{

  },[])
  //(0&&sessionDataRef.current['authenticated'])? <LoggedIn sessionData={sessionData} />: <EnrollmentForm />}/>

  return (
    
    <>  
    <SessionContext.Provider value={{"setSession":setSessionData,"getSession":getSession}}>
      <Routes>
        <Route path="/" element={<Splash />}/>
        <Route path="/e" element={<EnrollmentForm />}/>
        {/* <Route path="/w" element={<WoundInfo />}/>
        <Route path="/b" element={<RateFeeling />}/>
        <Route path="/a" element={<AddressForm />}/>
        <Route path="/address_change" element={<AddressChangeForm />}/>
        <Route path="/ps" element={<EnrollThanks />}/>
        <Route path="/bn" element={<BuyNowSlides />}/>
        <Route path="/dpn" element={sessionDataRef.current['qstate'] == 5 ? <LoggedIn sessionData={sessionData} />: <DpnStudy />}/>
        <Route path="/l" element={<LoggedIn sessionData={sessionData}/>}/> */}
        <Route path='/404' element={<FourOhFour/>} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </SessionContext.Provider>
    </>
  );
}

export default App;
