
import './Toast.scss'

function toast(type,text, parent, location=null){

    const toast = document.createElement("div");

    const newContent = document.createTextNode(text);

    //switch for type but for now only success
    var toastLocation = 'toast'

    switch (location){
        case 'top':
            toastLocation = 'toastTop'
            break
        case 'bottom':
            toastLocation = 'toastBottom'
            break
        default:
            break;
    }

    toast.classList = toastLocation+' success_message '+type

    toast.appendChild(newContent);

    toast.addEventListener('animationend',()=>{toast.remove()})
    toast.addEventListener('click',()=>{toast.remove()})

    parent.appendChild(toast);

}

export {toast}