function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    if(cookieValue == null){
        cookieValue = window?.init?.csrfmiddlewaretoken
    }
    if(cookieValue == null){
        console.log("csrf cookie missing")
    }
    return cookieValue;
}

function toggleShowPassword(e,id){
    var password = document.querySelector('#'+id)

    var type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    e.target.classList.toggle('bi-eye')

}

function formatPhoneNumber(number){
        
    var output=''
    var input;
    if(Number.isInteger(number)){
        input = number.toString();
    }
    else{
        input = number
    }
    
    input = input.replace(/[^0-9]/g, '');
    
        var totalLen = input.length
        var area = input.substr(0, 3);
        var pre = input.substr(3, 3);
        var tel = input.substr(6, 4);

        if (totalLen > 0 && totalLen < 4) {
        output = "(" + area;
        } else if (totalLen >= 4 && totalLen <= 6) {
        output = "(" + area + ")" + " " + pre;
        } else if (totalLen >= 7 ) {
        output = "(" + area + ")" + " " + pre + "-" + tel;
        }

    return [output,area+pre+tel]
}

//e must be a form submission event
//uses EITHER a white list OR black list, whichever is provided
//if neither is provided then it returns all form elements serialized into a dictionary object
function collectData(e, ignorables=[],includables=[]){

    var data = {}
    if(ignorables.length && includables.length){ //not allowed
        return null
    }

    for (let [k, v] of new FormData(e.target)) {
        if(ignorables.length){
            if(!(ignorables.includes(k))){
                data[k] = v
            }
        }
        else if(includables.length){
            if(includables.includes(k)){
                data[k] = v 
            }
        }
        else{
            data[k] = v
        }
    }

    return data 
}


function transmuteKey(data,key,newKey,newValue=null) {
    if(key in data){
        if(newValue != null){
            data[newKey] = newValue
        }
        else{
            data[newKey] = data[key]
        }
        delete data[key]
    }
}

function generateToken(){
    return Math.random().toString(36).substr(2)
}

export {getCookie, toggleShowPassword, formatPhoneNumber, generateToken, collectData, transmuteKey}