function show_spinner(button, spinner, inner){
    button.setAttribute('disabled','disabled')
    button.style.padding='10px 3em'
    button.style.color='rgba(1, 119, 255, 0)'

    inner.style.opacity='1'
    inner.style.display='block'
    spinner.style.opacity='1'
    spinner.style.display='block'
}

function hide_spinner(spinner, inner){
    inner.style.opacity='0'
    inner.style.display='none'
    spinner.style.opacity='0'
    spinner.style.display='none'
}
function send_error(button, spinner, inner){
    hide_spinner(spinner, inner)
    button.removeAttribute('disabled')
    button.style.color=null
    button.style.padding=null
}
function send_success(checkBox, spinner, inner){
    hide_spinner(spinner, inner)
    checkBox.classList.add('active')
}
function reverse_success(button, checkBox){
    button.removeAttribute('disabled')
    button.style.padding='10px 8px'
    button.style.color='white'

    checkBox.classList.remove('active')
}

export {show_spinner, hide_spinner, send_error, send_success, reverse_success}